import http from "@/utils/services/http"

export const listControleRgi = (limit, page, filtros) => {
    let url = '/api/controle-rgi?page=' + page + '&limit=' + limit + filtros
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const findControleRgi = (id) => {
    let url = `/api/controle-rgi/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newControleRgi = (data) => {
    let url = `/api/controle-rgi`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateControleRgi = (documento, method = 'PUT') => {
    let url = `/api/controle-rgi/${documento.id}`
    let _method = http.put
    if (method === 'PATCH') {
        _method = http.patch
    }
    return _method(url, documento)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteControleRgi = (id) => {
    let url = `/api/controle-rgi/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const uploadDocumento = (id, data) => {
    let url = `/api/controle-rgi/${id}/arquivo`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const downloadDocumento = (documento, arquivo, inline = false) => {
    let url = `/api/controle-rgi/${documento}/arquivos/${arquivo}/download`
    if (inline) {
        url = url + '?inline=true'
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            if (response.data instanceof Blob) {
                return new Promise((resolve, reject) => {
                    const fr = new FileReader();
                    fr.onload = function () {
                        const r = JSON.parse(this.result)
                        reject({data: r})
                    }
                    fr.readAsText(response.data)
                })
            } else {
                return Promise.reject(response)
            }
        })
}
